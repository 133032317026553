import React from "react"
import CancelIcon from "@material-ui/icons/Cancel"
import Chip from "@material-ui/core/Chip"
import Image from "gatsby-image"
import styled from "@emotion/styled"

const ProjectContainer = styled.div``

const ProjectDetailsContainer = styled.div`
  background: #202040;
  max-height: 90vh;
  overflow: auto;
  width: 80vw;
  max-width: 1100px;
  z-index: 350;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 20px;
  padding-top: 10px;
`

const Background = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 300;
`
const StyledCancelIcon = styled(CancelIcon)`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
`
const ProjectTitle = styled.h2`
  text-align: center;
  margin: 20px auto;
`

const ProjectGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 0 30px;

  @media (min-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }
`
const ProjectImageContainer = styled.div`
  & .gatsby-image-wrapper {
    height: 500px;
  }
`

const LeftGrid = styled.div``
const RightGrid = styled.div``

const ProjectDescription = styled.div``

const DescriptionHeading = styled.h3`
  margin-bottom: 15px;
`
const ProjectDetails = styled.div``
const DetailsHeading = styled.h3`
  margin-bottom: 5px;
  margin-top: 15px;
`
const Detail = styled.div`
  padding: 10px 0 15px;
  display: flex;
  flex-wrap: ${props => props.wrap && "wrap"};
`

const A = styled.a`
  margin-left: 10px;
  color: ${props => props.color};
  font-weight: 900;
`
const Span = styled.span`
  margin-left: 10px;
`

const StyledChip = styled(Chip)`
  margin-left: 15px;
  margin-bottom: 10px;
`

const ProjectDetailsBox = ({
  setClicked,
  title,
  image,
  details,
  github,
  url,
  color,
  technologyUsed,
}) => {
  return (
    <ProjectContainer>
      <Background onClick={() => setClicked(false)} className="alert" />
      <ProjectDetailsContainer className="alert">
        <StyledCancelIcon onClick={() => setClicked(false)} />
        <ProjectTitle>{title}</ProjectTitle>
        <ProjectGrid>
          <LeftGrid>
            <ProjectImageContainer>
              <Image fluid={image.fluid} style={{ borderRadius: 0 }} />
            </ProjectImageContainer>
          </LeftGrid>

          <RightGrid>
            <ProjectDescription>
              <DescriptionHeading>Project Info: </DescriptionHeading>
              <div>{details}</div>
            </ProjectDescription>
            <br />
            <ProjectDetails>
              <DetailsHeading>Project Details: </DetailsHeading>
              <Detail>
                <h4>Created By :</h4>
                <Span>Ayush Mainali</Span>
              </Detail>
              <Detail>
                <h4>Github :</h4>
                <A href={github} target="_blank" color={color}>
                  Click here to go to project link.
                </A>
              </Detail>
              <Detail>
                <h4>Live Demo :</h4>
                <A href={url} target="_blank" color={color}>
                  Click here to go to live demo.
                </A>
              </Detail>
              <Detail wrap>
                <h4>Technologies:</h4>
                {technologyUsed.map((tech, i) => (
                  <StyledChip label={tech} key={i} />
                ))}
              </Detail>
            </ProjectDetails>
          </RightGrid>
        </ProjectGrid>
      </ProjectDetailsContainer>
    </ProjectContainer>
  )
}

export default ProjectDetailsBox
