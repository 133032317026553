import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageTransition from "gatsby-plugin-page-transitions"
import PageTitle from "../components/PageTitle"
import { useStateValue } from "../contextProvider/StateProvider"
import { graphql, useStaticQuery } from "gatsby"
import Project from "../components/Project"
import styled from "@emotion/styled"


const PortfolioContainer = styled.div`
  margin-bottom: 100px;
  @media (min-width: 768px) {
    width: 75vw;
    margin: auto;
    margin-bottom: 100px;
  }
`

const PortfolioGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  gap: 35px;
  overflow: hidden;

`

const query = graphql`
  query projectsQuery {
    allContentfulProjects(sort: { fields: createdAt, order: ASC }) {
      nodes {
        title
        details: childContentfulProjectsDetailsRichTextNode {
          content {
            content {
              value
            }
          }
        }
        technologyUsed
        id
        image {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        json: childContentfulProjectsLinksJsonNode {
          github
          url
        }
      }
    }
  }
`

const Portfolio = () => {
  const [{ color }] = useStateValue()
  const data = useStaticQuery(query)
  return (
    <Layout>
      <SEO title="Portfolio" />
      <PortfolioContainer>
        <PageTransition
          transitionStyles={{
            entering: { opacity: "0.2" },
            entered: { opacity: "1" },
            exiting: { opacity: "0" },
          }}
          transitionTime={300}
        >
          <PageTitle
            backTitle={"Resume"}
            frontTitle={"MY"}
            coloredTitle={"Portfolio"}
            color={color}
          />
          <PortfolioGrid>
            {data.allContentfulProjects.nodes.map(
              ({ details, id, image, json, technologyUsed, title }, i) => (
                <Project
                  color={color}
                  details={details.content[0].content[0].value}
                  image={image}
                  github={json.github}
                  url={json.url}
                  technologyUsed={technologyUsed}
                  title={title}
                  key={id}
                />
              )
            )}
          </PortfolioGrid>
        </PageTransition>
      </PortfolioContainer>
    </Layout>
  )
}

export default Portfolio
