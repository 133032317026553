import React, { useState} from "react"
import Image from "gatsby-image"
import ProjectDetailsBox from '../components/ProjectDetailsBox'
import { CSSTransition } from "react-transition-group"
import '../css/transition.css'
import styled from "@emotion/styled"



const ProjectContainer = styled.div`
  overflow: hidden;
  position: relative;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: ${props => props.color};
  border-radius: 10px;
  opacity: 0;
  cursor: pointer;
  transition: 0.5s;
  display: grid;
  place-items: center;
  font-weight: 550;
  font-size: 20px;
  padding: 0 10px;
  text-align: center;
  &:hover {
    opacity: 1;
  }
`

const ImageContainer = styled.div`
  & .gatsby-image-wrapper {
    height: 250px;
    border-radius: 10px;
  }
`

const Project = ({
  color,
  details,
  image,
  github,
  url,
  technologyUsed,
  title,
}) => {
  const [clicked, setClicked] = useState(false)

  return (
    <ProjectContainer>
      <Overlay
        color = {color}
        onClick={() => setClicked(true)}
      >
        {title}
      </Overlay>
      <ImageContainer>
        <Image fluid={image.fluid} />
      </ImageContainer>
      
        <CSSTransition
          in={clicked}
          timeout={300}
          classNames="alert"
          unmountOnExit
        >
          <ProjectDetailsBox
            setClicked={setClicked}
            color={color}
            details={details}
            image={image}
            github={github}
            url={url}
            technologyUsed={technologyUsed}
            title={title}
          />
        </CSSTransition>
      
    </ProjectContainer>
  )
}

export default Project
